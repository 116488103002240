import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef, useState, useEffect } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function PersonCharacteristics({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);
  const [showMoveFields, setShowMoveFields] = useState<boolean>(false);

  // force MOVE fields hidden when:
  // - Hospital (114) is not in LIS & MOVE
  // - Provincie (62) is Buitenland (13)
  // This implementation speeds up the rendering of the form by removing a lot of unnecessary checks
  const fieldProvincie = methods.watch('62');
  const fieldHospital = methods.watch('114');
  useEffect(() => {
    setShowMoveFields(fieldHospital === true && fieldProvincie !== '13');
  }, [fieldProvincie, fieldHospital])
  
  const field_65 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 65, description: 'Alcohol', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_66 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 66, description: 'Drugs', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Persoonskenmerken</legend>
      <FormRow fieldId="65" isActive={visibility[65]} forceInactive={!showMoveFields} fieldRules={field_65.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_65.current.description}</span>
        <Controller
          control={methods.control}
          name="65"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_65.current.options} valueRules={field_65.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="66" isActive={visibility[66]} forceInactive={!showMoveFields} fieldRules={field_66.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_66.current.description}</span>
        <Controller
          control={methods.control}
          name="66"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_66.current.options} valueRules={field_66.current.valueRules} />
          )}
        />;
      </FormRow>

    </FormFieldset>
  )
}