import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef, useState, useEffect } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function Attributions({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);
  const [showMoveFields, setShowMoveFields] = useState<boolean>(false);

  // force MOVE fields hidden when:
  // - Hospital (114) is not in LIS & MOVE
  // - Provincie (62) is Buitenland (13)
  // This implementation speeds up the rendering of the form by removing a lot of unnecessary checks
  const fieldProvincie = methods.watch('62');
  const fieldHospital = methods.watch('114');
  useEffect(() => {
    setShowMoveFields(fieldHospital === true && fieldProvincie !== '13');
  }, [fieldProvincie, fieldHospital])

  const field_81 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 81, description: 'Toedracht', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_82 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 82, description: 'Uitglijden', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_83 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 83, description: 'Stuurfout', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_84 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 84, description: 'Uit de bocht', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_85 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 85, description: 'Schrikken', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_86 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 86, description: 'In slaap vallen', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_87 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 87, description: 'Niet opletten', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_88 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 88, description: 'Elkaar niet zien', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_89 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 89, description: 'Geen voorrang verlenen', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_90 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 90, description: 'Afsnijden', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_91 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 91, description: 'Door rood rijden', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_92 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 92, description: 'Te hard rijden', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_93 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 93, description: 'Uitwijken', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_94 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 94, description: 'Remmen', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_104 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 104, description: 'Sturen in elkaar', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_96 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 96, description: 'Uit balans', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_97 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 97, description: 'Trapper', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_98 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 98, description: 'Beknelling lichaam', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_99 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 99, description: 'Beknelling anders ', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_100 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 100, description: 'Toedracht tegenpartij', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_101 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 101, description: 'Toedracht obstakel', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_95 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 95, description: 'Overig', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Toedrachten</legend>
      <FormRow fieldId="81" isActive={visibility[81]} forceInactive={!showMoveFields} fieldRules={field_81.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_81.current.description}</span>
        <Controller
          control={methods.control}
          name="81"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_81.current.options} valueRules={field_81.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="82" isActive={visibility[82]} forceInactive={!showMoveFields} fieldRules={field_82.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_82.current.description}</span>
        <Controller
          control={methods.control}
          name="82"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_82.current.options} valueRules={field_82.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="83" isActive={visibility[83]} forceInactive={!showMoveFields} fieldRules={field_83.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_83.current.description}</span>
        <Controller
          control={methods.control}
          name="83"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_83.current.options} valueRules={field_83.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="84" isActive={visibility[84]} forceInactive={!showMoveFields} fieldRules={field_84.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_84.current.description}</span>
        <Controller
          control={methods.control}
          name="84"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_84.current.options} valueRules={field_84.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="85" isActive={visibility[85]} forceInactive={!showMoveFields} fieldRules={field_85.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_85.current.description}</span>
        <Controller
          control={methods.control}
          name="85"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_85.current.options} valueRules={field_85.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="86" isActive={visibility[86]} forceInactive={!showMoveFields} fieldRules={field_86.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_86.current.description}</span>
        <Controller
          control={methods.control}
          name="86"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_86.current.options} valueRules={field_86.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="87" isActive={visibility[87]} forceInactive={!showMoveFields} fieldRules={field_87.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_87.current.description}</span>
        <Controller
          control={methods.control}
          name="87"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_87.current.options} valueRules={field_87.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="88" isActive={visibility[88]} forceInactive={!showMoveFields} fieldRules={field_88.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_88.current.description}</span>
        <Controller
          control={methods.control}
          name="88"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_88.current.options} valueRules={field_88.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="89" isActive={visibility[89]} forceInactive={!showMoveFields} fieldRules={field_89.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_89.current.description}</span>
        <Controller
          control={methods.control}
          name="89"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_89.current.options} valueRules={field_89.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="90" isActive={visibility[90]} forceInactive={!showMoveFields} fieldRules={field_89.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_90.current.description}</span>
        <Controller
          control={methods.control}
          name="90"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_90.current.options} valueRules={field_90.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="91" isActive={visibility[91]} forceInactive={!showMoveFields} fieldRules={field_89.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_91.current.description}</span>
        <Controller
          control={methods.control}
          name="91"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_91.current.options} valueRules={field_91.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="92" isActive={visibility[92]} forceInactive={!showMoveFields} fieldRules={field_89.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_92.current.description}</span>
        <Controller
          control={methods.control}
          name="92"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_92.current.options} valueRules={field_92.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="93" isActive={visibility[93]} forceInactive={!showMoveFields} fieldRules={field_93.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_93.current.description}</span>
        <Controller
          control={methods.control}
          name="93"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_93.current.options} valueRules={field_93.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="94" isActive={visibility[94]} forceInactive={!showMoveFields} fieldRules={field_94.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_94.current.description}</span>
        <Controller
          control={methods.control}
          name="94"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_94.current.options} valueRules={field_94.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="104" isActive={visibility[104]} forceInactive={!showMoveFields} fieldRules={field_104.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_104.current.description}</span>
        <Controller
          control={methods.control}
          name="104"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_104.current.options} valueRules={field_104.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="96" isActive={visibility[96]} forceInactive={!showMoveFields} fieldRules={field_96.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_96.current.description}</span>
        <Controller
          control={methods.control}
          name="96"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_96.current.options} valueRules={field_96.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="97" isActive={visibility[97]} forceInactive={!showMoveFields} fieldRules={field_97.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_97.current.description}</span>
        <Controller
          control={methods.control}
          name="97"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_97.current.options} valueRules={field_97.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="98" isActive={visibility[98]} forceInactive={!showMoveFields} fieldRules={field_98.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_98.current.description}</span>
        <Controller
          control={methods.control}
          name="98"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_98.current.options} valueRules={field_98.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="99" isActive={visibility[99]} forceInactive={!showMoveFields} fieldRules={field_99.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_99.current.description}</span>
        <Controller
          control={methods.control}
          name="99"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_99.current.options} valueRules={field_99.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="100" isActive={visibility[100]} forceInactive={!showMoveFields} fieldRules={field_100.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_100.current.description}</span>
        <Controller
          control={methods.control}
          name="100"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_100.current.options} valueRules={field_100.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="101" isActive={visibility[101]} forceInactive={!showMoveFields} fieldRules={field_101.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_101.current.description}</span>
        <Controller
          control={methods.control}
          name="101"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_101.current.options} valueRules={field_101.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="95" isActive={visibility[95]} forceInactive={!showMoveFields} fieldRules={field_95.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_95.current.description}</span>
        <Controller
          control={methods.control}
          name="95"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_95.current.options} valueRules={field_95.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}