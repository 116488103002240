import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useCallback, useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule, UniquePairsInfo } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';
import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import { useFormValidators } from '@src/support/hooks/useFormValidators';

type Props = {
  readOnly?: boolean,
}

// setup unique pairs for 'letstel'
const uniquePairsLetsel: UniquePairsInfo = {
  pairs: [
    { field1: '55', field2: '56' },
    { field1: '57', field2: '58' },
    { field1: '59', field2: '60' }
  ]
}

export default function Diagnose({ readOnly = false }: Props) {

  const methods = useFormContext();
  const setValue = methods.setValue;

  const { isSet, uniquePairs, conditionalRequired, shiftEmptyPairs, triggerRelatedPairs } = useFormValidators();
  const { visibility, updateHandler, isVisible } = useFormVisibility();
  const { record } = useAppSelector(state => state.moveRecord)
  const { cache } = useAppSelector(state => state.cache)

  // shift empty pairs when needed
  const uniquePairsLetselChanged = useCallback((value: any, field?: string) => {
    if (field) {
      setValue(field, value, { shouldDirty: true });
      shiftEmptyPairs(uniquePairsLetsel, field);
      triggerRelatedPairs(uniquePairsLetsel);
    }
  }, [setValue, shiftEmptyPairs, triggerRelatedPairs]);

  const formFields = useRef<FormField[]>(cache.formFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.formFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.formValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.formValueRules || []);

  const field_55 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 55, description: 'Letsel type 1', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_56 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 56, description: 'Lichaamsdeel 1', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_57 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 57, description: 'Letsel type 2', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_58 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 58, description: 'Lichaamsdeel 2', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_59 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 59, description: 'Letsel type 3', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_60 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 60, description: 'Lichaamsdeel 3', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Diagnose</legend>
      <FormRow fieldId="77" isActive={true} classNames={{ leftColumn: 'col-12', rightColumn: 'd-none' }}>
        <span>{record?.[77]}</span>
        <></>
      </FormRow>

      <FormRow fieldId="55" isActive={visibility[55]} fieldRules={field_55.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_55.current.description}</span>
        <Controller
          control={methods.control}
          name="55"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '55', value),
              required: (value) => isSet(value),
              
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={uniquePairsLetselChanged} options={field_55.current.options} valueRules={field_55.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="56" isActive={visibility[56]} fieldRules={field_56.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_56.current.description}</span>
        <Controller
          control={methods.control}
          name="56"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '56', value),
              required: (value) => isSet(value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error}
              onChange={uniquePairsLetselChanged} options={field_56.current.options} valueRules={field_56.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="57" isActive={visibility[57]} fieldRules={field_57.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_57.current.description}</span>
        <Controller
          control={methods.control}
          name="57"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '57', value),
              required: (value) => conditionalRequired([{ field: '58', value: 'any' }], value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error}
              onChange={uniquePairsLetselChanged} options={field_57.current.options} valueRules={field_57.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="58" isActive={visibility[58]} fieldRules={field_58.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_58.current.description}</span>
        <Controller
          control={methods.control}
          name="58"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '58', value),
              required: (value) => conditionalRequired([{ field: '57', value: 'any' }], value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error}
              onChange={uniquePairsLetselChanged} options={field_58.current.options} valueRules={field_58.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="59" isActive={visibility[59]} fieldRules={field_59.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_59.current.description}</span>
        <Controller
          control={methods.control}
          name="59"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '59', value),
              required: (value) => conditionalRequired([{ field: '60', value: 'any' }], value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error}
              onChange={uniquePairsLetselChanged} options={field_59.current.options} valueRules={field_59.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="60" isActive={visibility[60]} fieldRules={field_60.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_60.current.description}</span>
        <Controller
          control={methods.control}
          name="60"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '60', value),
              required: (value) => conditionalRequired([{ field: '59', value: 'any' }], value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error}
              onChange={uniquePairsLetselChanged} options={field_60.current.options} valueRules={field_60.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}