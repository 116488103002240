import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef, useState, useEffect } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function EnvironmentalFactors({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { isVisible, visibility, updateHandler } = useFormVisibility();

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);
  const [showMoveFields, setShowMoveFields] = useState<boolean>(false);

  // force MOVE fields hidden when:
  // - Hospital (114) is not in LIS & MOVE
  // - Provincie (62) is Buitenland (13)
  // This implementation speeds up the rendering of the form by removing a lot of unnecessary checks
  const fieldProvincie = methods.watch('62');
  const fieldHospital = methods.watch('114');
  useEffect(() => {
    setShowMoveFields(fieldHospital === true && fieldProvincie !== '13');
  }, [fieldProvincie, fieldHospital])

  const field_76 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 76, description: 'Te water', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_77 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 77, description: 'Gladheid', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_78 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 78, description: 'Weer', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_79 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 79, description: 'Infra', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_80 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 80, description: 'Product/materiaal', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Omgevingsfactoren</legend>
      <FormRow fieldId="76" isActive={visibility[76]} forceInactive={!showMoveFields} fieldRules={field_76.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_76.current.description}</span>
        <Controller
          control={methods.control}
          name="76"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_76.current.options} valueRules={field_76.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="77" isActive={visibility[77]} forceInactive={!showMoveFields} fieldRules={field_77.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_77.current.description}</span>
        <Controller
          control={methods.control}
          name="77"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_77.current.options} valueRules={field_77.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="78" isActive={visibility[78]} forceInactive={!showMoveFields} fieldRules={field_78.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_78.current.description}</span>
        <Controller
          control={methods.control}
          name="78"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_78.current.options} valueRules={field_78.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="79" isActive={visibility[79]} forceInactive={!showMoveFields} fieldRules={field_79.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_79.current.description}</span>
        <Controller
          control={methods.control}
          name="79"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_79.current.options} valueRules={field_79.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="80" isActive={visibility[80]} forceInactive={!showMoveFields} fieldRules={field_80.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_80.current.description}</span>
        <Controller
          control={methods.control}
          name="80"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_80.current.options} valueRules={field_80.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}