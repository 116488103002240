import { WorkflowDefinition } from '@support/components/workflowView/models';

const workflowDefinitionTotal: WorkflowDefinition = {
  className: 'workflow workflow--sticky-header',
  columns: [{
    label: 'Status',
    key: 'status',
    expandable: true,
    className: 'workflow__col--w10',
  }, {
    label: 'Jaar',
    key: 'year',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Kwartaal',
    key: 'quarter',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Maand',
    key: 'month',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Datum',
    key: 'date',
    expandable: false,
    className: 'workflow__col--w10'
  }, {
    label: 'Drenthe',
    key: 'dr',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Friesland',
    key: 'fr',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Groningen',
    key: 'gr',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Limburg',
    key: 'li',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Noord-Brabant',
    key: 'nb',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Noord-Holland',
    key: 'nh',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  },
  {
    label: 'Utrecht',
    key: 'ut',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Zeeland',
    key: 'ze',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Totaal',
    key: 'total',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }]
};

export default workflowDefinitionTotal;